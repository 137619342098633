import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents untyped JSON */
  JSON: any;
};

export type Absence = {
  __typename?: 'Absence';
  endDate: Scalars['String'];
  finalFee?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startDate: Scalars['String'];
};

/** Autogenerated return type of ActionReport */
export type ActionReportPayload = {
  __typename?: 'ActionReportPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['String'];
  dateFrom: Scalars['String'];
  dateTo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  every?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<Report>>;
  style?: Maybe<Scalars['String']>;
  tags?: Maybe<Tag>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userName: Scalars['String'];
  weekdays?: Maybe<Array<Scalars['Int']>>;
};

export type App = {
  __typename?: 'App';
  childDob?: Maybe<Scalars['String']>;
  childFirstName?: Maybe<Scalars['String']>;
  childLastName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parentEmail?: Maybe<Scalars['String']>;
  parentFirstName?: Maybe<Scalars['String']>;
  parentLastName?: Maybe<Scalars['String']>;
  plannedDays?: Maybe<Array<Scalars['String']>>;
  plannedStartDate?: Maybe<Scalars['String']>;
};

export type Booking = {
  __typename?: 'Booking';
  endDate: Scalars['String'];
  enrolmentId: Scalars['ID'];
  id: Scalars['ID'];
  sessionCodeId: Scalars['ID'];
  startDate: Scalars['String'];
  style: Scalars['String'];
};

export type Capture = {
  __typename?: 'Capture';
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  date: Scalars['String'];
  errors?: Maybe<Array<Scalars['String']>>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  tags?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type CcsPaymentItem = {
  __typename?: 'CcsPaymentItem';
  amount?: Maybe<Scalars['Float']>;
  clearingDocumentNumber?: Maybe<Scalars['String']>;
  datePaid?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  enrolmentId: Scalars['String'];
  exempted: Scalars['Boolean'];
  id: Scalars['ID'];
  mainTransactionCode?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
  sessionReportStartDate: Scalars['String'];
  subTransactionCode?: Maybe<Scalars['String']>;
};

export type CcsPerson = {
  __typename?: 'CcsPerson';
  ccsUid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  level?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  usualEducatorAddressLine1?: Maybe<Scalars['String']>;
  usualEducatorAddressLine2?: Maybe<Scalars['String']>;
  usualEducatorAddressPostCode?: Maybe<Scalars['String']>;
  usualEducatorAddressState?: Maybe<Scalars['String']>;
  usualEducatorAddressSuburb?: Maybe<Scalars['String']>;
};

export type Child = {
  __typename?: 'Child';
  contacts?: Maybe<Array<Contact>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  everything?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Array<ChildInstruct>>;
  lastName: Scalars['String'];
};

export type ChildInstruct = {
  __typename?: 'ChildInstruct';
  category: Scalars['String'];
  description: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  isInternalEvaluation: Scalars['Boolean'];
  objId: Scalars['ID'];
  objType: Scalars['String'];
  serviceId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['String']>;
  user: User;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  obsPlatformAuthorised?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAbsence */
export type CreateAbsencePayload = {
  __typename?: 'CreateAbsencePayload';
  absence?: Maybe<Absence>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateCapture */
export type CreateCapturePayload = {
  __typename?: 'CreateCapturePayload';
  captures?: Maybe<Array<Capture>>;
};

/** Autogenerated return type of CreateComment */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateExtraSession */
export type CreateExtraSessionPayload = {
  __typename?: 'CreateExtraSessionPayload';
  /** The created booking */
  booking?: Maybe<Booking>;
  /** Errors messages if any */
  errors: Array<Scalars['String']>;
};

/** Autogenerated return type of CreateForm */
export type CreateFormPayload = {
  __typename?: 'CreateFormPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  form?: Maybe<Form>;
};

/** Autogenerated return type of CreateInvoice */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  errors?: Maybe<Array<Scalars['String']>>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated return type of CreateNote */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  errors?: Maybe<Array<Scalars['String']>>;
  note?: Maybe<Note>;
};

/** Autogenerated return type of CreateNotificationView */
export type CreateNotificationViewPayload = {
  __typename?: 'CreateNotificationViewPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateSessionSale */
export type CreateSessionSalePayload = {
  __typename?: 'CreateSessionSalePayload';
  errors?: Maybe<Array<Scalars['String']>>;
  session?: Maybe<ServiceSession>;
};

/** Autogenerated return type of CreateSmartForm */
export type CreateSmartFormPayload = {
  __typename?: 'CreateSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  form?: Maybe<SmartForm>;
};

/** Autogenerated return type of CreateTemplate */
export type CreateTemplatePayload = {
  __typename?: 'CreateTemplatePayload';
  errors?: Maybe<Array<Scalars['String']>>;
  template?: Maybe<Report>;
};

export type CustomTag = {
  __typename?: 'CustomTag';
  colour?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of DeleteComment */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteInvoice */
export type DeleteInvoicePayload = {
  __typename?: 'DeleteInvoicePayload';
  errors?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteKindyhubObject */
export type DeleteKindyhubObjectPayload = {
  __typename?: 'DeleteKindyhubObjectPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteSessionSale */
export type DeleteSessionSalePayload = {
  __typename?: 'DeleteSessionSalePayload';
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of DeleteSmartForm */
export type DeleteSmartFormPayload = {
  __typename?: 'DeleteSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  form?: Maybe<SmartForm>;
};

export type EBooking = {
  __typename?: 'EBooking';
  day: Scalars['String'];
  room?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of EditComment */
export type EditCommentPayload = {
  __typename?: 'EditCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type Enrolment = {
  __typename?: 'Enrolment';
  bookings?: Maybe<Array<EBooking>>;
  ccsMaxHrs?: Maybe<Scalars['String']>;
  ccsPercentage?: Maybe<Scalars['String']>;
  ccsWithholding?: Maybe<Scalars['String']>;
  child: Child;
  childId: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  entitlement?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  noObservation?: Maybe<Scalars['Boolean']>;
  parent1: Parent;
  parent2?: Maybe<ParentTwo>;
  parentId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type Form = {
  __typename?: 'Form';
  answers?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fileURL: Scalars['String'];
  formDate?: Maybe<Scalars['String']>;
  gridMode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  subject: Scalars['String'];
  submittedBy: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type FormConfiguration = {
  __typename?: 'FormConfiguration';
  config?: Maybe<Scalars['String']>;
  gridMode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  layout: Scalars['String'];
  name: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  parentId: Scalars['ID'];
  sent: Scalars['Boolean'];
  token: Scalars['String'];
  type: Scalars['String'];
};

export type LearningOutcome = {
  __typename?: 'LearningOutcome';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  system: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  actionReport?: Maybe<ActionReportPayload>;
  createAbsence?: Maybe<CreateAbsencePayload>;
  createCapture?: Maybe<CreateCapturePayload>;
  /** Creates a new comment */
  createComment?: Maybe<CreateCommentPayload>;
  createExtraSession?: Maybe<CreateExtraSessionPayload>;
  createForm?: Maybe<CreateFormPayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a new notification view */
  createNotificationViews?: Maybe<CreateNotificationViewPayload>;
  createSession?: Maybe<CreateSessionSalePayload>;
  /** Creates a new form */
  createSmartForm?: Maybe<CreateSmartFormPayload>;
  createTemplate?: Maybe<CreateTemplatePayload>;
  /** Deletes a comment */
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteInvoice?: Maybe<DeleteInvoicePayload>;
  deleteKindyhubObject?: Maybe<DeleteKindyhubObjectPayload>;
  deleteSession?: Maybe<DeleteSessionSalePayload>;
  /** deletes an existing form */
  deleteSmartForm?: Maybe<DeleteSmartFormPayload>;
  /** Edits a comment */
  editComment?: Maybe<EditCommentPayload>;
  revertReport?: Maybe<RevertReportPayload>;
  saveActivity?: Maybe<SaveActivityPayload>;
  saveCapture?: Maybe<SaveCapturePayload>;
  saveReport?: Maybe<SaveReportPayload>;
  saveTags?: Maybe<SaveTagsPayload>;
  /** Updates an existing form */
  updateSmartForm?: Maybe<UpdateSmartFormPayload>;
};


export type MutationActionReportArgs = {
  action: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationCreateAbsenceArgs = {
  bookingId: Scalars['String'];
  date: Scalars['String'];
  enrolmentIdSwappedTo?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCaptureArgs = {
  date: Scalars['String'];
  fileData: Scalars['String'];
  serviceId: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  body: Scalars['String'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']>;
  objId: Scalars['ID'];
  objType?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};


export type MutationCreateExtraSessionArgs = {
  date: Scalars['String'];
  enrolmentId: Scalars['ID'];
  roomId: Scalars['ID'];
};


export type MutationCreateFormArgs = {
  answers: Scalars['String'];
  formDate?: InputMaybe<Scalars['String']>;
  formSubmissionId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  smartFormId: Scalars['ID'];
};


export type MutationCreateInvoiceArgs = {
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  fdcEducatorId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  type: Scalars['String'];
  weeksToProject?: InputMaybe<Scalars['String']>;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  parentId: Scalars['String'];
  pubToKiosk: Scalars['Boolean'];
  pubToParent: Scalars['Boolean'];
};


export type MutationCreateNotificationViewsArgs = {
  notificationId: Scalars['ID'];
};


export type MutationCreateSessionArgs = {
  enrolmentId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationCreateSmartFormArgs = {
  config?: InputMaybe<Scalars['JSON']>;
  gridMode: Scalars['Boolean'];
  layout: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};


export type MutationCreateTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteKindyhubObjectArgs = {
  objId: Scalars['Int'];
  objType: Scalars['String'];
};


export type MutationDeleteSessionArgs = {
  enrolmentId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationDeleteSmartFormArgs = {
  id: Scalars['ID'];
};


export type MutationEditCommentArgs = {
  body: Scalars['String'];
  id: Scalars['ID'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRevertReportArgs = {
  id: Scalars['Int'];
  versionId: Scalars['Int'];
};


export type MutationSaveActivityArgs = {
  activityId?: InputMaybe<Scalars['ID']>;
  dateFrom: Scalars['String'];
  dateTo?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  every?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  serviceId: Scalars['String'];
  style?: InputMaybe<Scalars['String']>;
  timeFrom?: InputMaybe<Scalars['String']>;
  timeTo?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  weekdays: Array<Scalars['Int']>;
};


export type MutationSaveCaptureArgs = {
  caption?: InputMaybe<Scalars['String']>;
  captureId: Scalars['ID'];
  serviceId: Scalars['String'];
};


export type MutationSaveReportArgs = {
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  reportId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['String'];
  smartFormSubmissionIds?: InputMaybe<Array<Scalars['Int']>>;
  template?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationSaveTagsArgs = {
  children?: InputMaybe<Array<Scalars['Int']>>;
  customTags?: InputMaybe<Array<Scalars['Int']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']>>;
  newCustomTags?: InputMaybe<Array<Scalars['String']>>;
  nextActivities?: InputMaybe<Array<Scalars['Int']>>;
  objId: Scalars['Int'];
  objType: Scalars['String'];
  rooms?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateSmartFormArgs = {
  config?: InputMaybe<Scalars['JSON']>;
  gridMode: Scalars['Boolean'];
  id: Scalars['ID'];
  layout: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  ackAt?: Maybe<Scalars['String']>;
  ackBy?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdByName: Scalars['String'];
  id: Scalars['ID'];
  parentId: Scalars['String'];
  parentName: Scalars['String'];
  reply?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  actionType: Scalars['String'];
  capture?: Maybe<Capture>;
  comment?: Maybe<Comment>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  objId: Scalars['ID'];
  objType: Scalars['String'];
  report?: Maybe<Report>;
  seenBy?: Maybe<Array<Scalars['ID']>>;
};

export type NotificationCollectionMetadata = {
  __typename?: 'NotificationCollectionMetadata';
  total: Scalars['Int'];
};

export type NotificationMetadata = {
  __typename?: 'NotificationMetadata';
  notificationIds: Array<Scalars['Int']>;
  objType: Scalars['String'];
  seenIds: Array<Scalars['Int']>;
  total: Scalars['Int'];
  unseenCount: Scalars['Int'];
};

export type NotificationWithMetadata = {
  __typename?: 'NotificationWithMetadata';
  metadata: NotificationCollectionMetadata;
  notifications: Array<Notification>;
};

export type Parent = {
  __typename?: 'Parent';
  balance?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  everything?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  lastName: Scalars['String'];
  payments?: Maybe<Array<Payment>>;
  phone?: Maybe<Scalars['String']>;
};


export type ParentBalanceArgs = {
  dateTo?: InputMaybe<Scalars['String']>;
};


export type ParentPaymentsArgs = {
  dateFrom?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
};

export type ParentTwo = {
  __typename?: 'ParentTwo';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  paymentDate: Scalars['String'];
  transactionDate: Scalars['String'];
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /** Find a service by ID */
  service: Service;
};


/** The query root of this schema */
export type QueryServiceArgs = {
  id: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  date: Scalars['String'];
  history?: Maybe<Array<ReportEvent>>;
  id: Scalars['ID'];
  isTemplate?: Maybe<Scalars['Boolean']>;
  smartFormSubmissionIds?: Maybe<Array<Scalars['Int']>>;
  status: Scalars['String'];
  tags?: Maybe<Tag>;
  template?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type ReportEvent = {
  __typename?: 'ReportEvent';
  createdAt: Scalars['String'];
  event: Scalars['String'];
  id: Scalars['ID'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

/** Autogenerated return type of RevertReport */
export type RevertReportPayload = {
  __typename?: 'RevertReportPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  report?: Maybe<Report>;
};

export type Room = {
  __typename?: 'Room';
  fdcEducatorId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inactive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SaveActivity */
export type SaveActivityPayload = {
  __typename?: 'SaveActivityPayload';
  activity?: Maybe<Activity>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of SaveCapture */
export type SaveCapturePayload = {
  __typename?: 'SaveCapturePayload';
  capture?: Maybe<Capture>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of SaveReport */
export type SaveReportPayload = {
  __typename?: 'SaveReportPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  report?: Maybe<Report>;
};

/** Autogenerated return type of SaveTags */
export type SaveTagsPayload = {
  __typename?: 'SaveTagsPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  activities?: Maybe<Array<Activity>>;
  applications?: Maybe<Array<App>>;
  bookedSessions?: Maybe<Array<ServiceSession>>;
  captures?: Maybe<Array<Capture>>;
  ccsPayments?: Maybe<Array<CcsPaymentItem>>;
  comments?: Maybe<Array<Comment>>;
  customTags?: Maybe<Array<CustomTag>>;
  educators?: Maybe<Array<CcsPerson>>;
  enrolments: Array<Enrolment>;
  formConfig: Scalars['String'];
  formConfigurations?: Maybe<Array<FormConfiguration>>;
  forms?: Maybe<Array<Form>>;
  hasPaymentGateway?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  learningOutcomes?: Maybe<Array<LearningOutcome>>;
  name: Scalars['String'];
  notes?: Maybe<Array<Note>>;
  notifications?: Maybe<NotificationWithMetadata>;
  notificationsMetadata?: Maybe<Array<NotificationMetadata>>;
  report?: Maybe<Report>;
  reports?: Maybe<Array<Report>>;
  rooms: Array<Room>;
  sessions?: Maybe<Array<ServiceSession>>;
  sessionsAvailable?: Maybe<Array<ServiceSession>>;
  smartForm?: Maybe<SmartForm>;
  staffAtts?: Maybe<Array<StaffAtt>>;
  templateGroups: TemplateGroups;
  type: Scalars['String'];
  tzName?: Maybe<Scalars['String']>;
  userOrganisationRoles?: Maybe<Array<User>>;
};


export type ServiceActivitiesArgs = {
  children?: InputMaybe<Array<Scalars['Int']>>;
  customTags?: InputMaybe<Array<Scalars['Int']>>;
  dateFrom: Scalars['String'];
  dateTo?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']>>;
  rooms?: InputMaybe<Array<Scalars['Int']>>;
};


export type ServiceApplicationsArgs = {
  status: Scalars['String'];
};


export type ServiceBookedSessionsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type ServiceCapturesArgs = {
  children?: InputMaybe<Array<Scalars['Int']>>;
  customTags?: InputMaybe<Array<Scalars['Int']>>;
  dateFrom: Scalars['String'];
  dateTo?: InputMaybe<Scalars['String']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']>>;
  rooms?: InputMaybe<Array<Scalars['Int']>>;
};


export type ServiceCcsPaymentsArgs = {
  weekEnding: Scalars['String'];
};


export type ServiceCommentsArgs = {
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']>;
  objId: Scalars['Int'];
  objType: Scalars['String'];
};


export type ServiceEnrolmentsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  fdcEducatorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type ServiceFormsArgs = {
  formDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedSince?: InputMaybe<Scalars['String']>;
};


export type ServiceNotesArgs = {
  createdSince?: InputMaybe<Scalars['String']>;
};


export type ServiceNotificationsArgs = {
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']>;
  objType?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type ServiceNotificationsMetadataArgs = {
  objType?: InputMaybe<Scalars['String']>;
};


export type ServiceReportArgs = {
  id: Scalars['Int'];
};


export type ServiceReportsArgs = {
  children?: InputMaybe<Array<Scalars['Int']>>;
  customTags?: InputMaybe<Array<Scalars['Int']>>;
  dateFrom: Scalars['String'];
  dateTo?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']>>;
  rooms?: InputMaybe<Array<Scalars['Int']>>;
  template?: InputMaybe<Scalars['String']>;
};


export type ServiceSessionsArgs = {
  date?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
};


export type ServiceSessionsAvailableArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  enrolmentId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type ServiceSmartFormArgs = {
  id: Scalars['ID'];
};


export type ServiceStaffAttsArgs = {
  date: Scalars['String'];
};


export type ServiceTemplateGroupsArgs = {
  dateFrom: Scalars['String'];
  dateTo?: InputMaybe<Scalars['String']>;
};


export type ServiceUserOrganisationRolesArgs = {
  category?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ServiceSession = {
  __typename?: 'ServiceSession';
  absent?: Maybe<Scalars['Boolean']>;
  attendanceStatus?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  bookingPattern?: Maybe<Scalars['String']>;
  bookingStyle?: Maybe<Scalars['String']>;
  ccsMaxHrs?: Maybe<Scalars['String']>;
  ccsPercentage?: Maybe<Scalars['String']>;
  ccsWithholding?: Maybe<Scalars['String']>;
  childFirstName?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['ID']>;
  childLastName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  enrolmentId?: Maybe<Scalars['ID']>;
  fee?: Maybe<Scalars['Float']>;
  inOut?: Maybe<Array<SessionInOut>>;
  miscFees?: Maybe<Array<SessionMiscFee>>;
  noObservation?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  sessionCodeId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SessionInOut = {
  __typename?: 'SessionInOut';
  confirmHealthy?: Maybe<Scalars['String']>;
  estDepTime?: Maybe<Scalars['String']>;
  in?: Maybe<Scalars['String']>;
  inBy?: Maybe<Scalars['String']>;
  inEditedBy?: Maybe<Scalars['String']>;
  out?: Maybe<Scalars['String']>;
  outBy?: Maybe<Scalars['String']>;
  outEditedBy?: Maybe<Scalars['String']>;
  whoSignOut?: Maybe<Scalars['String']>;
};

export type SessionMiscFee = {
  __typename?: 'SessionMiscFee';
  amount: Scalars['Float'];
  code: Scalars['String'];
  dailySessionId: Scalars['String'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fdcEducatorId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type SmartForm = {
  __typename?: 'SmartForm';
  config?: Maybe<Scalars['String']>;
  gridMode: Scalars['Boolean'];
  id: Scalars['ID'];
  layout: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['Int'];
};

export type StaffAtt = {
  __typename?: 'StaffAtt';
  date: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  in: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  out: Scalars['String'];
  roomName?: Maybe<Scalars['String']>;
  unpaid: Scalars['Boolean'];
};

export type Tag = {
  __typename?: 'Tag';
  children?: Maybe<Array<Scalars['Int']>>;
  customTags?: Maybe<Array<Scalars['Int']>>;
  learningOutcomes?: Maybe<Array<Scalars['Int']>>;
  nextActivities?: Maybe<Array<Scalars['Int']>>;
  rooms?: Maybe<Array<Scalars['Int']>>;
};

export type TemplateGroups = {
  __typename?: 'TemplateGroups';
  templates: Array<Scalars['String']>;
};

/** Autogenerated return type of UpdateSmartForm */
export type UpdateSmartFormPayload = {
  __typename?: 'UpdateSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  form?: Maybe<SmartForm>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  userOrgDetails?: Maybe<UserOrgDetails>;
};

export type UserOrgDetails = {
  __typename?: 'UserOrgDetails';
  id: Scalars['ID'];
  role: Scalars['String'];
  roleEnd?: Maybe<Scalars['String']>;
  roleStart?: Maybe<Scalars['String']>;
};

export type GetEnrolmentsQueryVariables = Exact<{
  srvId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  fdcEducatorId?: InputMaybe<Scalars['String']>;
}>;


export type GetEnrolmentsQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', id: string, status: string, type: string, child: { __typename?: 'Child', id: string, firstName: string, lastName: string }, parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string } }> } };

export type GetChildrenListQueryVariables = Exact<{
  srvId: Scalars['ID'];
  fdcEducatorId?: InputMaybe<Scalars['String']>;
}>;


export type GetChildrenListQuery = { __typename?: 'Query', service: { __typename?: 'Service', formConfig: string, enrolments: Array<{ __typename?: 'Enrolment', id: string, childId: string, status: string, entitlement?: string | null, startDate?: string | null, endDate?: string | null, child: { __typename?: 'Child', id: string, firstName: string, gender?: string | null, dateOfBirth?: string | null, lastName: string, everything?: string | null, instructions?: Array<{ __typename?: 'ChildInstruct', category: string, description: string }> | null, contacts?: Array<{ __typename?: 'Contact', firstName: string, lastName: string, email?: string | null, mobile?: string | null, phone?: string | null, role: string }> | null }, parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string, email?: string | null, phone?: string | null } }> } };

export type GetEnrolmentsParentChildQueryVariables = Exact<{
  srvId: Scalars['ID'];
}>;


export type GetEnrolmentsParentChildQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', childId: string, parentId: string }> } };

export type GetParentBalanceQueryVariables = Exact<{
  srvId: Scalars['ID'];
  enrolmentId?: InputMaybe<Scalars['String']>;
}>;


export type GetParentBalanceQuery = { __typename?: 'Query', service: { __typename?: 'Service', hasPaymentGateway?: boolean | null, enrolments: Array<{ __typename?: 'Enrolment', parent1: { __typename?: 'Parent', id: string, balance?: string | null } }> } };

export type GetParentsBalanceListQueryVariables = Exact<{
  srvId: Scalars['ID'];
  fdcEducatorId?: InputMaybe<Scalars['String']>;
}>;


export type GetParentsBalanceListQuery = { __typename?: 'Query', service: { __typename?: 'Service', formConfig: string, hasPaymentGateway?: boolean | null, enrolments: Array<{ __typename?: 'Enrolment', id: string, parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string, email?: string | null, everything?: string | null, payments?: Array<{ __typename?: 'Payment', id: string, amount: string, description: string, paymentDate: string, transactionDate: string }> | null } }> } };

export type GetParentInvoiceListQueryVariables = Exact<{
  srvId: Scalars['ID'];
  enrolmentId?: InputMaybe<Scalars['String']>;
}>;


export type GetParentInvoiceListQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', id: string, parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string, invoices?: Array<{ __typename?: 'Invoice', dateFrom: string, dateTo: string, id: string, note?: string | null, parentId: string, sent: boolean, token: string, type: string }> | null } }> } };

export type GetNotesQueryVariables = Exact<{
  srvId: Scalars['ID'];
  fdcEducatorId?: InputMaybe<Scalars['String']>;
}>;


export type GetNotesQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string } }>, notes?: Array<{ __typename?: 'Note', id: string, content?: string | null, parentId: string, parentName: string, ackAt?: string | null, ackBy?: string | null, channel?: string | null, createdBy: string, createdByName: string, reply?: string | null }> | null } };

export type GetEducatorsQueryVariables = Exact<{
  srvId: Scalars['ID'];
}>;


export type GetEducatorsQuery = { __typename?: 'Query', service: { __typename?: 'Service', educators?: Array<{ __typename?: 'CcsPerson', firstName: string, lastName: string, ccsUid: string, email?: string | null }> | null } };

export type GetServiceSessionsQueryVariables = Exact<{
  srvId: Scalars['ID'];
  date: Scalars['String'];
  dateTo: Scalars['String'];
  room: Scalars['String'];
}>;


export type GetServiceSessionsQuery = { __typename?: 'Query', service: { __typename?: 'Service', sessions?: Array<{ __typename?: 'ServiceSession', absent?: boolean | null, attendanceStatus?: string | null, childFirstName?: string | null, childId?: string | null, childLastName?: string | null, code?: string | null, date?: string | null, endTime?: string | null, enrolmentId?: string | null, fee?: number | null, room?: string | null, roomId?: string | null, sessionCodeId?: string | null, startTime?: string | null, inOut?: Array<{ __typename?: 'SessionInOut', in?: string | null, out?: string | null, inBy?: string | null, inEditedBy?: string | null, outBy?: string | null, outEditedBy?: string | null }> | null, miscFees?: Array<{ __typename?: 'SessionMiscFee', id: string, code: string, date: string, amount: number, description?: string | null, fdcEducatorId?: string | null, dailySessionId: string }> | null }> | null } };

export type GetBookedSessionsQueryVariables = Exact<{
  srvId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type GetBookedSessionsQuery = { __typename?: 'Query', service: { __typename?: 'Service', bookedSessions?: Array<{ __typename?: 'ServiceSession', absent?: boolean | null, attendanceStatus?: string | null, childFirstName?: string | null, childId?: string | null, childLastName?: string | null, code?: string | null, date?: string | null, endTime?: string | null, enrolmentId?: string | null, fee?: number | null, room?: string | null, roomId?: string | null, sessionCodeId?: string | null, startTime?: string | null, inOut?: Array<{ __typename?: 'SessionInOut', in?: string | null, out?: string | null }> | null, miscFees?: Array<{ __typename?: 'SessionMiscFee', id: string, code: string, date: string, amount: number, description?: string | null, fdcEducatorId?: string | null, dailySessionId: string }> | null }> | null } };

export type GetEnrolmentEntitlementQueryVariables = Exact<{
  srvId: Scalars['ID'];
}>;


export type GetEnrolmentEntitlementQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', id: string, entitlement?: string | null, childId: string, child: { __typename?: 'Child', id: string, firstName: string, lastName: string } }> } };

export type GetCcsPaymentsQueryVariables = Exact<{
  srvId: Scalars['ID'];
  weekEnding: Scalars['String'];
}>;


export type GetCcsPaymentsQuery = { __typename?: 'Query', service: { __typename?: 'Service', ccsPayments?: Array<{ __typename?: 'CcsPaymentItem', id: string, sessionReportStartDate: string, datePaid?: string | null, clearingDocumentNumber?: string | null, documentNumber?: string | null, mainTransactionCode?: string | null, subTransactionCode?: string | null, amount?: number | null, exempted: boolean, serviceId: string, enrolmentId: string }> | null } };

export type PostNewNoteMutationVariables = Exact<{
  parentId: Scalars['String'];
  content: Scalars['String'];
}>;


export type PostNewNoteMutation = { __typename?: 'Mutation', createNote?: { __typename?: 'CreateNotePayload', errors?: Array<string> | null, note?: { __typename?: 'Note', id: string } | null } | null };

export type PostNewInvoiceMutationVariables = Exact<{
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  fdcEducatorId: Scalars['String'];
  parentId: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  weeksToProject?: InputMaybe<Scalars['String']>;
}>;


export type PostNewInvoiceMutation = { __typename?: 'Mutation', createInvoice?: { __typename?: 'CreateInvoicePayload', errors?: Array<string> | null, invoice?: { __typename?: 'Invoice', id: string } | null } | null };

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvoiceMutation = { __typename?: 'Mutation', deleteInvoice?: { __typename?: 'DeleteInvoicePayload', errors?: Array<string> | null, status?: string | null } | null };


export const GetEnrolmentsDocument = gql`
    query getEnrolments($srvId: ID!, $startDate: String, $endDate: String, $fdcEducatorId: String) {
  service(id: $srvId) {
    enrolments(
      startDate: $startDate
      endDate: $endDate
      fdcEducatorId: $fdcEducatorId
    ) {
      id
      status
      type
      child {
        id
        firstName
        lastName
      }
      parent1 {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export const GetChildrenListDocument = gql`
    query getChildrenList($srvId: ID!, $fdcEducatorId: String) {
  service(id: $srvId) {
    formConfig
    enrolments(fdcEducatorId: $fdcEducatorId) {
      id
      childId
      status
      entitlement
      startDate
      endDate
      child {
        id
        firstName
        gender
        dateOfBirth
        lastName
        everything
        instructions {
          category
          description
        }
        contacts {
          firstName
          lastName
          email
          mobile
          phone
          role
        }
      }
      parent1 {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
}
    `;
export const GetEnrolmentsParentChildDocument = gql`
    query getEnrolmentsParentChild($srvId: ID!) {
  service(id: $srvId) {
    enrolments {
      childId
      parentId
    }
  }
}
    `;
export const GetParentBalanceDocument = gql`
    query getParentBalance($srvId: ID!, $enrolmentId: String) {
  service(id: $srvId) {
    enrolments(id: $enrolmentId) {
      parent1 {
        id
        balance
      }
    }
    hasPaymentGateway
  }
}
    `;
export const GetParentsBalanceListDocument = gql`
    query getParentsBalanceList($srvId: ID!, $fdcEducatorId: String) {
  service(id: $srvId) {
    formConfig
    hasPaymentGateway
    enrolments(fdcEducatorId: $fdcEducatorId) {
      id
      parent1 {
        id
        firstName
        lastName
        email
        payments {
          id
          amount
          description
          paymentDate
          transactionDate
        }
        everything
      }
    }
  }
}
    `;
export const GetParentInvoiceListDocument = gql`
    query getParentInvoiceList($srvId: ID!, $enrolmentId: String) {
  service(id: $srvId) {
    enrolments(id: $enrolmentId) {
      id
      parent1 {
        id
        firstName
        lastName
        invoices {
          dateFrom
          dateTo
          id
          note
          parentId
          sent
          token
          type
        }
      }
    }
  }
}
    `;
export const GetNotesDocument = gql`
    query getNotes($srvId: ID!, $fdcEducatorId: String) {
  service(id: $srvId) {
    enrolments(fdcEducatorId: $fdcEducatorId) {
      parent1 {
        id
        firstName
        lastName
      }
    }
    notes {
      id
      content
      parentId
      parentName
      ackAt
      ackBy
      channel
      createdBy
      createdByName
      reply
    }
  }
}
    `;
export const GetEducatorsDocument = gql`
    query getEducators($srvId: ID!) {
  service(id: $srvId) {
    educators {
      firstName
      lastName
      ccsUid
      email
    }
  }
}
    `;
export const GetServiceSessionsDocument = gql`
    query getServiceSessions($srvId: ID!, $date: String!, $dateTo: String!, $room: String!) {
  service(id: $srvId) {
    sessions(date: $date, dateTo: $dateTo, room: $room) {
      absent
      attendanceStatus
      childFirstName
      childId
      childLastName
      code
      date
      endTime
      enrolmentId
      fee
      room
      roomId
      sessionCodeId
      startTime
      inOut {
        in
        out
        inBy
        inEditedBy
        outBy
        outEditedBy
      }
      miscFees {
        id
        code
        date
        amount
        description
        fdcEducatorId
        dailySessionId
      }
    }
  }
}
    `;
export const GetBookedSessionsDocument = gql`
    query getBookedSessions($srvId: ID!, $startDate: String, $roomId: String, $endDate: String) {
  service(id: $srvId) {
    bookedSessions(startDate: $startDate, roomId: $roomId, endDate: $endDate) {
      absent
      attendanceStatus
      childFirstName
      childId
      childLastName
      code
      date
      endTime
      enrolmentId
      fee
      room
      roomId
      sessionCodeId
      startTime
      inOut {
        in
        out
      }
      miscFees {
        id
        code
        date
        amount
        description
        fdcEducatorId
        dailySessionId
      }
    }
  }
}
    `;
export const GetEnrolmentEntitlementDocument = gql`
    query getEnrolmentEntitlement($srvId: ID!) {
  service(id: $srvId) {
    enrolments {
      id
      entitlement
      childId
      child {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export const GetCcsPaymentsDocument = gql`
    query getCCSPayments($srvId: ID!, $weekEnding: String!) {
  service(id: $srvId) {
    ccsPayments(weekEnding: $weekEnding) {
      id
      sessionReportStartDate
      datePaid
      clearingDocumentNumber
      documentNumber
      mainTransactionCode
      subTransactionCode
      amount
      exempted
      serviceId
      enrolmentId
    }
  }
}
    `;
export const PostNewNoteDocument = gql`
    mutation postNewNote($parentId: String!, $content: String!) {
  createNote(
    pubToKiosk: true
    pubToParent: true
    parentId: $parentId
    content: $content
  ) {
    errors
    note {
      id
    }
  }
}
    `;
export const PostNewInvoiceDocument = gql`
    mutation postNewInvoice($dateFrom: String!, $dateTo: String!, $fdcEducatorId: String!, $parentId: String!, $sendEmail: Boolean!, $note: String, $weeksToProject: String) {
  createInvoice(
    dateFrom: $dateFrom
    dateTo: $dateTo
    fdcEducatorId: $fdcEducatorId
    parentId: $parentId
    sendEmail: $sendEmail
    type: "fdc"
    note: $note
    weeksToProject: $weeksToProject
  ) {
    errors
    invoice {
      id
    }
  }
}
    `;
export const DeleteInvoiceDocument = gql`
    mutation deleteInvoice($id: Int!) {
  deleteInvoice(id: $id) {
    errors
    status
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getEnrolments(variables: GetEnrolmentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEnrolmentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEnrolmentsQuery>(GetEnrolmentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEnrolments', 'query');
    },
    getChildrenList(variables: GetChildrenListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetChildrenListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChildrenListQuery>(GetChildrenListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChildrenList', 'query');
    },
    getEnrolmentsParentChild(variables: GetEnrolmentsParentChildQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEnrolmentsParentChildQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEnrolmentsParentChildQuery>(GetEnrolmentsParentChildDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEnrolmentsParentChild', 'query');
    },
    getParentBalance(variables: GetParentBalanceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetParentBalanceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetParentBalanceQuery>(GetParentBalanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getParentBalance', 'query');
    },
    getParentsBalanceList(variables: GetParentsBalanceListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetParentsBalanceListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetParentsBalanceListQuery>(GetParentsBalanceListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getParentsBalanceList', 'query');
    },
    getParentInvoiceList(variables: GetParentInvoiceListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetParentInvoiceListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetParentInvoiceListQuery>(GetParentInvoiceListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getParentInvoiceList', 'query');
    },
    getNotes(variables: GetNotesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNotesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetNotesQuery>(GetNotesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNotes', 'query');
    },
    getEducators(variables: GetEducatorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEducatorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEducatorsQuery>(GetEducatorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEducators', 'query');
    },
    getServiceSessions(variables: GetServiceSessionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetServiceSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServiceSessionsQuery>(GetServiceSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServiceSessions', 'query');
    },
    getBookedSessions(variables: GetBookedSessionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBookedSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBookedSessionsQuery>(GetBookedSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBookedSessions', 'query');
    },
    getEnrolmentEntitlement(variables: GetEnrolmentEntitlementQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEnrolmentEntitlementQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEnrolmentEntitlementQuery>(GetEnrolmentEntitlementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEnrolmentEntitlement', 'query');
    },
    getCCSPayments(variables: GetCcsPaymentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCcsPaymentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCcsPaymentsQuery>(GetCcsPaymentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCCSPayments', 'query');
    },
    postNewNote(variables: PostNewNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PostNewNoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostNewNoteMutation>(PostNewNoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postNewNote', 'mutation');
    },
    postNewInvoice(variables: PostNewInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PostNewInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostNewInvoiceMutation>(PostNewInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postNewInvoice', 'mutation');
    },
    deleteInvoice(variables: DeleteInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteInvoiceMutation>(DeleteInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteInvoice', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;